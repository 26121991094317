import React from "react";

import useStyles from '../styles/portfolio';

import image1 from '../images/portfolio/nynj.png';
import image2 from '../images/portfolio/trueconnect.png';
import image3 from '../images/portfolio/mediacutlet.png';
import image4 from '../images/portfolio/scalzo.png';
import image5 from '../images/portfolio/germforce.png';
import image6 from '../images/portfolio/caris.png';

function Contact() {
  const classes = useStyles();

  const portfolioItems = [
    {
      tag: 'Web Development',
      tech: 'Wordpress',
      title: 'NY & NJ MSDC',
      image: image1,
      link: 'http://nynjmsdc.org/',
      desc: 'At Websignia I maintained and devleoped web updates for the New York & New Jersey Minority Supplier Development Council.',
    },
    {
      tag: 'Web Development',
      tech: 'React & Node.Js',
      title: 'TrueConnect',
      image: image2,
      link: 'https://trueconnect.io/',
      desc: 'At Disrupt I led small team for the development of TrueConnect. A better way to keep in contact with the people you are meeting with.',
    },
    {
      tag: 'Web Development & Support',
      tech: 'Wordpress',
      title: 'MediaCutlet',
      image: image3,
      link: 'https://mediacutlet.com/',
      desc: 'At MediaCutlet I develop web solutions for their clients.',
    },
    {
      tag: 'Web Support',
      tech: 'Wordpress',
      title: 'Scalzo',
      image: image4,
      link: 'https://scalzoclean.com/',
      desc: 'A MediaCutlet client I assist with web development and maintenance.',
    },
    {
      tag: 'Web Development',
      tech: 'Wordpress & Laravel',
      title: 'Caris Life Sciences',
      image: image6,
      link: 'https://www.carislifesciences.com/',
      desc: 'At LocalWisdom I developed this site with a small team.',
    },
    {
      tag: 'Web Development',
      tech: 'Wordpress',
      title: 'The Germ Force',
      image: image5,
      link: 'https://thegermforce.com/',
      desc: 'At MediaCutlet I led the development of this new site for a new sub-brand of Scalzo.',
    },
  ];

  const portfolio = portfolioItems.map((site) =>
    <div className='col-50'>
      <div className={`${classes.siteContainer} mobile-siteContainer`}>
        <a href={site.link} target='_blank' rel='noopener noreferrer'>
          <img className={classes.siteImg} src={site.image} alt='site preview' />
        </a>
        <div className={`${classes.textContainer} mobile-textContainer`}>
          <p className={classes.siteTag}>{site.tag} -
            <span style={{ color: '#BE0B16' }}> {site.tech}</span>
          </p>
          <a href={site.link} target='_blank' rel='noopener noreferrer'>
            <h4 className={`${classes.siteTitle} flex-center`}>{site.title}</h4>
          </a>
          <p>{site.desc}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.portfolioRoot}>
      <section>
        <div className='row container'>
          <h1 className={`${classes.heading} tracking-in-expand`}>Portfolio</h1>
        </div>
      </section>
      <section style={{ paddingTop: '0' }}>
        <div className='row container'>
          <h2 className='flex-center' style={{ color: '#BE0B16'}}>My Work</h2>
        </div>
        <div className='row container'>
          {portfolio}
        </div>
      </section>
    </div>
  );
}

export default Contact;
