import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  portfolioRoot: {
    width: '100%',
    height: '100%',
    display: 'block',
    minHeight: '100vh',
  },
  heading: {
    padding: '0',
    margin: '0',
    lineHeight: '1',
    fontSize: theme.typography.pxToRem(100),
    fontWeight: '700',
    fontFamily: 'Poppins',
    letterSpacing: '0',
    textShadow: '1px 1px 10px rgba(0,0,0,0.25)',
  },
  siteContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
  },
  siteImg: {
    width: '100%',
    borderRadius: '7px',
    position: 'relative',
    boxShadow:
      '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  textContainer: {
    padding: '15px 20px',
    textAlign: 'center',
  },
  siteTag: {
    textTransform: 'uppercase',
    color: '#bbbbbb',
    fontSize: '0.8em',
  },
  siteTitle: {
    margin: '0 10px',
    fontSize: '1.75em',
  },
}));

export default useStyles;
